var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('auth-layout',[_c('div',{staticClass:"login ma-0 pa-4 pb-0 bg-color--blue color--white"},[(_vm.actionShown === 'login')?_c('form',{ref:"loginForm",staticClass:"w-100",on:{"submit":function($event){$event.preventDefault();return _vm.login($event)}}},[_c('div',{staticClass:"lg:ml-20"},[_c('div',[_c('h1',{staticClass:"text-4xl mb-5 font-thin text-loginPageTitle"},[_vm._v(" Get Started ")]),_c('p',{staticClass:"font-thin mb-33 text-loginPageSubTitle"},[_vm._v(" Enter your email below and we’ll send a text with a verification code. ")]),_c('div',{staticClass:"relative flex w-full flex-wrap items-stretch mb-2 mt-12"},[_c('span',{staticClass:"z-10 h-full leading-snug font-normal absolute text-center\n                  text-black absolute bg-transparent rounded text-base items-center\n                  justify-center w-5 pl-0 py-3 pt-4"},[_c('img',{attrs:{"src":require("../../assets/email_icon_light_green.png")}})]),_c('t-input',{staticClass:"px-3 py-3 focus:outline-white placeholder-lightGreen text-black\n                  relative text-sm w-full pl-8 appearance-none rounded-br-none rounded-bl-none\n                  shadow-none border-t-0 border-l-0 border-r-0 border-b-1 bg-opacity-0 ",class:{
                  'textfield-default--black--filled': _vm.email,
                  'textfield-default--black--error': _vm.errorMessage,
                },attrs:{"name":"email","type":"email","placeholder":"jon.snow@thewall.north","rules":[
                  function (v) { return !!v || 'Email must not be blank'; },
                  function (v) { return /.+@.+\..+/.test(v) || 'E-mail must be valid'; } ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),(_vm.errorMessage)?_c('p',{staticClass:"color--deepblue font--14 text-gray-500"},[_c('span',{staticClass:"font-bold"},[_vm._v(" Hmm, this doesn't look right. ")]),_c('br'),_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()]),_c('div',{staticClass:"login__bottom-col"},[(!_vm.dataLoading)?_c('div',{staticClass:"hover:bg-loginButtonHover button-default text-white float-right\n                rounded-full bg-lightNeonGreen px-14 py-3 mt-4 font-semibold text-sm\n                 shadow-sendCodeButtonShadow cursor-pointer",attrs:{"id":"send_code_btn","color":"#000750","dark":""},on:{"click":_vm.login}},[_vm._v(" SEND MY CODE ")]):_c('div',{staticClass:"hover:bg-loginButtonHover button-default text-white float-right\n                rounded-full bg-lightNeonGreen px-24 py-3 mt-4 font-semibold text-sm\n                 shadow-sendCodeButtonShadow cursor-pointer",attrs:{"id":"send_code_btn","color":"#000750","dark":""}},[_c('div',{staticClass:"loader"},[_vm._v(" Loading... ")])])])])]):_vm._e(),(_vm.actionShown === 'verify')?_c('form',{staticClass:"w-100",on:{"submit":function($event){$event.preventDefault();return _vm.verify($event)}}},[_c('div',[_c('div',[_c('h1',{staticClass:"text-4xl mb-4 font-thin text-loginPageTitle"},[_vm._v(" Verification code sent. ")]),_c('p',{staticClass:"font-thin mb-33 text-loginPageSubTitle"},[_vm._v(" Check your text messages and enter the six-digit code to login. ")]),_c('div',{staticClass:"login__code-wrapper mt-10"},_vm._l((_vm.CODE_LENGTH),function(i){return _c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.code[i - 1]),expression:"code[i - 1]"}],key:i,ref:("codeInput-" + (i - 1)),refInFor:true,staticClass:"textfield-code m-1 text-black bg-gray-100 h-14 w-10 text-2xl text-center rounded",class:{
                  'textfield-code--filled': _vm.code[i - 1],
                  'textfield-code--error': _vm.errorMessage,
                },attrs:{"color":"#000","type":"text","maxLength":"1","size":"1","min":"0","max":"9","pattern":"[0-9]*"},domProps:{"value":(_vm.code[i - 1])},on:{"keyup":function($event){return _vm.changeInputFocus(i - 1)},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.code, i - 1, $event.target.value)}}})}),0),(_vm.errorMessage != '')?_c('p',{staticClass:"color--deepblue font--14 text-black"},[_c('span',{staticClass:"text-loginPageTitle",staticStyle:{"font-weight":"600","font-size":"16px"}},[_vm._v(" Hmm, this doesn't look right. ")]),_c('br')]):_vm._e(),_c('p',{staticClass:"text-loginPageSubTitle",staticStyle:{"max-width":"calc(100% - 100px)","font-weight":"400","font-size":"15px"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]),_c('p')]),_c('div',{staticClass:"login__bottom-col"},[(!_vm.dataLoading)?_c('div',{staticClass:"hover:bg-loginButtonHover button-default text-white float-left rounded-full\n                bg-lightNeonGreen px-14 py-3 mt-4 font-semibold text-sm\n                shadow-sendCodeButtonShadow cursor-pointer",attrs:{"id":"verify_code_btn","color":"#000750"},on:{"click":_vm.verify}},[_vm._v(" Verify ")]):_c('div',{staticClass:"hover:bg-loginButtonHover button-default text-white float-left rounded-full\n                bg-lightNeonGreen px-14 py-3 mt-4 font-semibold text-sm\n                shadow-sendCodeButtonShadow cursor-pointer",attrs:{"id":"verify_code_btn","color":"#000750","dark":""}},[_c('div',{staticClass:"loader"},[_vm._v(" Loading... ")])]),_c('p',{staticClass:"font--14"},[_vm._v(" Text didn't arrive? "),_c('br'),_c('a',{staticClass:"font-bold text-lightGreen float-right md:float-left md:ml-32 text-underline cursor-pointer",on:{"click":function($event){return _vm.login(true)}}},[_vm._v(" Send another code ")])])])])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }